<template>  
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="updateMultipleTaskStatus"/>
        <!-- <v-dialog v-model="isTaskCompleted"> -->
            <ATaskComplete @close="reloadPage" v-if="isTaskCompleted" />
        <!-- </v-dialog> -->
        <v-skeleton-loader
        v-if="this.api.isLoading"
        ref="skeleton"
        type="table">
        </v-skeleton-loader>
        <v-row no-gutters v-if="!api.isLoading">
            <v-col class="mx-auto mt-6">
                <v-container>
                    <v-data-table
                    :items="task"
                    :search="searchResult"
                    :headers = "headersTask"
                    class="elevation-1 ">
                        <template v-slot:item.company_name="{item}">
                            <router-link v-if="item.company_id!=33" :to="{name:'PageCompaniesDetail',params:{id:item.company_id}}">
                                {{ item.company_name }}
                            </router-link>  
                            <span
                                v-else>
                                -
                            </span>
                        </template>
                        <template v-slot:item.role_id="{item}">
                            <span v-if="item.pic_id!=null">
                                {{ item.pic_name}}
                            </span>
                            <span v-if="item.role_id!=null">
                                {{ (item.role_name=='vision_admin')?"Admin":item.role_name.replaceAll('_',' ') }}
                            </span>
                        </template>
                        <template v-slot:item.created_at="{item}">
                            <!-- {{ item.created_at.split('T')[0].split(" ")[0] }}
                            {{ convertTimeZone(item.created_at).split(" ")[3] }} {{  convertTimeZone(item.created_at).split(" ")[4]}} -->

                            {{ convertTimeZone(item.created_at) }}
                        </template>

                        <template v-slot:item.priority="{item}">
                            <span v-if="item.priority=='Urgent'" class="font-weight-bold my-3 red--text" >
                                {{ item.priority }}
                            </span>
                            <span v-if="item.priority=='Medium'" style="color: #FBB955;" class="font-weight-bold my-3 ">
                                Mid
                            </span>
                            <span v-if="item.priority=='Low'" class="font-weight-bold my-3 green-text" >
                                Low
                            </span>
                        </template>
                        <template v-slot:item.task_category="{item}">
                            <router-link v-if="item.task_category=='Client Subscription Renewal'|| item.task_category=='Client Payment Failed'" :to="{name:'PageCompaniesDetail',params:{'id':item.company_id}}">
                                {{ item.task_category }}
                            </router-link>
                            <span v-else>
                                {{ item.task_category }}
                            </span>
                        </template>
                        <template v-slot:item.task_pickup="{item}">
                            <div :key="item.task_id">
                                <v-switch inset v-if="$store.getters.getCurrentTask!=null && $store.getters.getCurrentTask.includes(item.task_id)" v-model="isTaskActiveTrue" label="Active" @click="setInactiveTask(item)">
                                </v-switch>
                                <v-switch v-else inset v-model="isTaskActiveFalse" label="Inactive" @click="setActiveTask(item)">
                                </v-switch>

                            </div>
                        </template>
                        <template v-slot:item.index="{item}">
                            {{ (task.indexOf(item))+1 }}
                        </template>
                        <template
                            v-slot:item.task_status="{item}">
                            <v-checkbox
                                v-model="item.checkbox"
                                @change="updateCheckBox()">

                            </v-checkbox>
                        </template>
                        <!-- <template v-slot:item.task_status="{item}">
                            <div v-if="loadingUpdateStatus">
                                <v-progress-circular indeterminate color="primary">

                                </v-progress-circular>
                            </div>
                            <div v-else>
                                <v-checkbox v-if="item.status=='Done'" v-model="statusCheckedTrue" disabled>
                                </v-checkbox>
                                <v-checkbox v-else v-model="statusCheckedFalse" @change="validateInput(item,statusCheckedFalse)">
    
                                </v-checkbox>
                            </div>
                        </template> -->
                        <template v-slot:top>
                           
                                <v-toolbar flat class="pa-3"
                                    height="auto"
                                    width="auto">
                                    <v-row 
                                        no-gutters>
                                        <v-col
                                            class="mt-3 mx-3">
                                            <v-row>
                                                <v-col>
                                                    <v-text-field filled dense outlined clearable v-model="searchResult" label="Search">
                                                     </v-text-field>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col
                                                    class="pt-0 pb-2">
                                                    <div 
                                                        class="d-flex justify-end">
                                                        <v-btn 
                                                            :disabled="!isTaskSelected"
                                                            color="primary"
                                                            @click="validateInput">
                                                            Mark As Complete
                                                        </v-btn>
                                                    </div>
                                                </v-col>
                                            </v-row>
                                        </v-col>

                                    </v-row>
                                </v-toolbar>
                        </template>
                    </v-data-table>
                </v-container>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import { mapState } from 'vuex'
import AConfirmation from '../common/AConfirmation.vue';
import ATaskComplete from '../common/ATaskComplete.vue';
export default {
    components:{
    AConfirmation,
    ATaskComplete,
},
    computed: mapState({
    }),
    props:[
    //
    ],
    data: () => ({
        isTaskSelected:false,
        currentTaskId:null,
        loadingUpdateStatus:false,
        statusCheckedTrue:true,
        istaskActiveFalse:false,
        isTaskActiveTrue:true,
        statusCheckedFalse:false,
        tempTaskItem:null,
        tempStatusChecked:null,

        tab:null,
        task:[],
        headersTask:[
            {
                text:'No.',
                value:'index',
                sortable:false,
            },
          
            {
                text:'Company',
                value:'company_name',
            },
            // {
            //     text:'Assigned to',
            //     value:'pic_name',
            // },  
            {
                text:'Action',
                value:'task_category',

            },
            {
                text:'Description',
                value:'task_action'
            },  
          
            {
                text:'Date',
                value:'created_at',
            },
       

            // {
            //     text:'Deadline',
            //     value:'deadline_at',
            // },
            {
                text:'Priority',
                value:'priority'
            },
            {
                text:'Assigned by',
                value:'assignee_name',
            },
            {
                text:'PIC',
                value:'role_id',
            },
            {
                text:'Active',
                value:'task_pickup'
            },
            {
                text:'Done',
                value:'task_status',
                sortable:false,

            },
           
        ],
        currentUpdateId:null,
        userRole:null,
        isTaskCompleted:false,
        isPending:false,
        searchResult:null,
        api:{
            isSuccesful:false,
            isError:false,
            isLoading:false,
            error:null,
            url:null,
        }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class=="getTaskByStatus"){
                
                let tempTasks=[];
                resp.data.forEach(element=>{
                    let tempTask={
                        "assignee_name":element.assignee_name,
                        'company_id':element.company_id,
                        "company_name":element.company_name,
                        "completed_by":element.completed_by,
                        "completed_by_name":element.completed_by_name,
                        "created_at":element.created_at,
                        "checkbox":(element.task_status=='To do')?false:true,
                        "pic_id":element.pic_id,
                        "pic_name":element.pic_name,
                        "priority":element.priority,
                        "role_id":element.role_id,
                        "role_name":element.role_name,
                        "status":element.status,
                        "task_action":element.task_action,
                        "task_category":element.task_category,
                        "task_id":element.task_id,
                        "task_name":element.task_name,
                        "task_status":element.task_status,
                        "user_id":element.user_id,
                    }
                    tempTasks.push(tempTask);
                })
                this.task = tempTasks;
            }
            if(resp.class=="updateMultipleTaskStatus") {
                this.isTaskCompleted = true;
                // location.reload();
            }
            if(resp.class=="updateTaskStatus"){
                this.isTaskCompleted = true;
                let tempCurrentTask = this.$_.clone(this.$store.getters.getCurrentTask);
                tempCurrentTask.splice(tempCurrentTask.indexOf(this.currentTaskId),1);
                this.$store.commit("updateCurrentTask",tempCurrentTask);
                // location.reload();
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            this.currentTask = (this.$store.getters.getCurrentTask!=null)?this.$store.getters.getCurrentTask.map(item=>item["task_id"]):null,
            this.userRole = this.$store.getters.getRole;
            let fetchTaskBasedOnUserIncompleteAPi = this.fetchTaskBasedOnUserIncomplete();
            this.$api.fetch(fetchTaskBasedOnUserIncompleteAPi);

        },
        fetchTaskBasedOnUserIncomplete(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/task/status/"+this.$store.getters.getUserId+"?status=To do";
            return tempApi;
        },
        fetchTaskBasedOnUserComplete(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/task/status/"+this.$store.getters.getUserId+"/?status=Done";
            return tempApi;
        },
        validateInput(){
            this.loadingUpdateStatus = true;
            this.isPending = true;
           
        },
        submit()
        {
            this.isPending = false;
        },
        cancelSubmit(){
            this.statusCheckedFalse=false;
            this.loadingUpdateStatus = false;
            this.isPending = false;
        },
        redirectNewTask(){
            let companyId = this.$router.history.current.params.id;
            this.$router.push({name:'PageTaskCreate',params:{id:companyId}});
        },
        redirectReadTask(taskId){
            this.$router.push({name:'PageTaskDetail',params:{id:taskId}});
        },
        updateMultipleTaskStatus(){
            this.isPending = false;
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/task/multiple/status";
            let formData = new FormData;
            formData.append('userId',this.$store.getters.getUserId);
            formData.append('status',"Done");
            this.task.forEach(task=>{
                if(task.checkbox==true) {
                    formData.append('tasks[]',task.task_id);
                }
            })
            tempApi.params = formData;
            this.$api.fetch(tempApi);
        },
        updateTaskStatus(){
            this.isPending = false;
            let task = this.$_.clone(this.tempTaskItem);
            this.currentTaskId = task.task_id;
            let nextStatus = this.$_.clone(this.tempStatusChecked);
            this.statusCheckedFalse = false;
            if(nextStatus==true){
                let tempApi = this.$_.clone(this.api);
                tempApi.method = "PUT";
                tempApi.url = process.env.VUE_APP_SERVER_API+"/task/status/id/"+task.task_id;
                tempApi.params={
                    'status':'Done',
                    'userId':this.$store.getters.getUserId,
                };
                this.$axios.all([
                    this.$api.fetch(tempApi),
                    this.fetch(),

                ]);
            }

        },
        reloadPage(){
            location.reload();
        },
        setActiveTask(task){
            this.isTaskActiveFalse = false;
            let currentTask = (this.$store.getters.getCurrentTask!=null)?this.$_.clone(this.$store.getters.getCurrentTask):[];
            if(currentTask!=null){
                currentTask.forEach(taskSelected => {
                    if(taskSelected==task){
                        return 1;
                    }
                });
            }

            currentTask.push(task.task_id);
            this.$store.commit('updateCurrentTask',currentTask);

        },
        setInactiveTask(task){
            this.isTaskActiveTrue=true;
            let currentTask = (this.$store.getters.getCurrentTask!=null)?this.$_.clone(this.$store.getters.getCurrentTask):[];
            if(this.$store.getters.getCurrentTask.includes(task.task_id)){
                currentTask.splice(currentTask.indexOf(task.task_id),1);
                this.$store.commit('updateCurrentTask',currentTask);
            }
        },
        convertTimeZone(time){
            return this.$moment(time).format('LLL')
            // return new Date(new Date(time).toLocaleString("en-US",{timeZone:"Asia/Kuala_Lumpur"})).toString();
        },
        updateCheckBox(){
            this.isTaskSelected = false;
            this.task.forEach(element=>{
                if(element.checkbox===true){
                    this.isTaskSelected = true;
                }
            })
        },  
        create(){


        },
    }
}
</script>