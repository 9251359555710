<template>
    <v-container>
        <v-skeleton-loader
        v-if="this.api.isLoading"
        ref="skeleton"
        type="table">
        </v-skeleton-loader>
        <v-row no-gutters v-if="!api.isLoading">
            <v-col class="mx-auto mt-3">
                <v-container>
                    <v-data-table v-if="this.status=='complete'" :items="task" :headers="taskHeaderCompleted"
                    :search="searchResult"
                    class="elevation-1"
                    dense>
                        <template v-slot:item.index="{item}">
                            {{ (task.indexOf(item))+1 }}
                        </template>

                        <template v-slot:top>
                            <v-container >
                                <v-toolbar flat class="pa-3">
                                    <v-text-field filled dense outlined clearable v-model="searchResult" label="Search">
            
                                    </v-text-field>
                                </v-toolbar>
                            </v-container>
                        </template>

                        <template v-slot:item.task_status>
                            <div>
              
                                <v-checkbox style="color:green"
                                color="success"
                                disabled v-model="statusCheckedTrue">
                                </v-checkbox>
                            </div>
                           
                        </template>

                    </v-data-table>

                    <v-data-table v-if="this.status=='incomplete'" :items="task" :headers="taskHeaderIncompleted"
                    :search="searchResult"
                    class="elevation-1"
                    dense>

                        <template v-slot:item.pic_name="{item}">
                            <span v-if="item.pic_name!=null">
                                {{ item.pic_name }}
                            </span>
                            <span v-else>
                                {{ (item.role_name=='vision_admin')?"Admin":item.role_name.replaceAll("_"," ") }}
                            </span>
                        </template>
                        <template v-slot:item.index="{item}">
                            {{ (task.indexOf(item))+1 }}
                        </template>

                        <template v-slot:top>
                            <v-container >
                                <v-toolbar flat class="pa-3">
                                    <v-text-field filled dense outlined clearable v-model="searchResult" label="Search">
            
                                    </v-text-field>
                                </v-toolbar>
                            </v-container>
                        </template>

                        <template v-slot:item.task_status> 
                            <div>
                                <v-checkbox style="color:green"
                                color="success"
                                disabled v-model="statusCheckedFalse">
                                </v-checkbox>
                            </div>
                        </template>

                    </v-data-table>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapState } from 'vuex';



export default {
    computed: mapState({

    }),
    props:[
    'status'
    ],

    data:()=>({
        task:null,
        searchResult:null,
        statusCheckedFalse :false,
        statusCheckedTrue:true,
        taskHeaderIncompleted:[
            {
                text:'No.',
                value:'index',
            },
            {
                text:'Company',
                value:'company_name',
            },
            {
                text:'Action',
                value:'task_category',
            },
            {
                text:'Description',
                value:'task_action',
            },
            {
                text:'Date',
                value:'created_at',
            },
            {
                text:'Id',
                value:'task_name',
            },
            {
                text:'Priority',
                value:'priority',
            },
            {
                text:'Assigned to',
                value:'pic_name'
            },
           
            {
                text:'Done',
                value:'task_status',
            },
                
        ],
        taskHeaderCompleted:[
            {
                text:'No.',
                value:'index',
            },
            {
                text:'Company',
                value:'company_name',
            },
            {
                text:'Action',
                value:'task_category',
            },
            {
                text:'Description',
                value:'task_action',
            },
            {
                text:'Date',
                value:'created_at',
            },
            {
                text:'Id',
                value:'task_name',
            },
            {
                text:'Priority',
                value:'priority',
            },
            // {
            //     text:'Assigned to',
            //     value:'pic_name'
            // },
            {
                text:'Completed by',
                value:'completed_by_name',
            },
            {
                text:'Done',
                value:'task_status',
            },
                
        ],
        api:{
            isSuccesful:false,
            isError:false,
            isLoading:false,
            error:null,
            url:null,
        }
    }),

    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class=="getAssignedTaskBasedOnStatus"){
                this.task = resp.data;

            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted(){
        this.fetch();
    },

    methods:{
        fetch(){
            let fetchTaskAssignedApi = this.fetchTaskAssigned();
            this.$api.fetch(fetchTaskAssignedApi);
        },
        fetchTaskAssigned(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+'/task/assigned/id/'+this.$store.getters.getUserId+"?status="+this.status;
            return tempApi;
        },

    },
}
</script>