<template>  
    <v-container>
        <AConfirmation :isShow="isPending" @cancel="cancelSubmit" @confirm="updateTaskStatus"/>
        <ANotAllowed :notAllowed = "notAllowed" @close="closeNotAllowed" />
        <v-skeleton-loader
        v-if="this.api.isLoading"
        ref="skeleton"
        type="table">
        </v-skeleton-loader>
        <v-row no-gutters v-if="!api.isLoading">
            <v-col class="mx-auto mt-3">
                <v-container>
                    <v-data-table
                    :items="task"
                    :search="searchResult"
                    :headers = "headersTask"
                    class="elevation-1 ">
                        <template v-slot:item.created_at="{item}">
                            <!-- {{ item.created_at.split('T')[0].split(" ")[0] }}
                            {{ convertTimeZone(item.created_at).split(" ")[3] }} {{  convertTimeZone(item.created_at).split(" ")[4]}} -->
                            {{ convertTimeZone(item.created_at) }}
                        </template>
                        <template v-slot:item.role_id="{item}">
                            <span v-if="item.role_id!=null">
                                Team
                            </span>
                            <span v-if="item.user_id!=null">
                                Personal
                            </span>
                        </template>

                        <template v-slot:item.task_status="{item}">
                            <div v-if="loadingUpdateStatus">
                                <v-progress-circular indeterminate color="primary">

                                </v-progress-circular>
                            </div>
                            <div v-else>

                                    <v-checkbox style="color:green" color="success" v-if="item.task_status=='Done'" @change="validateInput(item,statusCheckedTrue)" v-model="statusCheckedTrue">
                                    </v-checkbox>
            
                                <!-- <v-checkbox v-else :value="statusCheckedFalse" @change="validateInput(item,statuscheckedFalse)">
                                </v-checkbox> -->
                            </div>
                        </template>

                        <template v-slot:item.priority="{item}">
        
                            <span v-if="item.priority=='Urgent'" class="font-weight-bold my-3 red--text" >
                                {{ item.priority }}
                            </span>
                            <span v-if="item.priority=='Medium'" style="color: #FBB955;" class="font-weight-bold my-3">
                                Mid
                            </span>
                            <span v-if="item.priority=='Low'" class="font-weight-bold my-3 green-text" >
                                Low
                            </span>
                        </template>
                        <template v-slot:item.company_name="{item}">
                            <router-link v-if="item.company_id!=33" :to="{name:'PageCompaniesDetail',params:{id:item.company_id}}">
                                {{ item.company_name }}
                            </router-link>  
                            <span v-else>
                                -
                            </span>
                        </template>
                        <template v-slot:item.task_category="{item}">
                            <router-link v-if="item.task_category=='Client Subscription Renewal'|| item.task_category=='Client Payment Failed'" :to="{name:'PageCompaniesDetail',params:{'id':item.company_id}}">
                                {{ item.task_category }}
                            </router-link>
                            <span v-else>
                                {{ item.task_category }}
                            </span>
                        </template>
                        <!-- <template v-slot:item.task_name="{item}">
                            <a @click="redirectReadTask(item.task_id)" class="text-decoration-none">
                            {{ item.task_name}}
                            </a>
                        </template> -->
                        
                        <template v-slot:item.index="{item}">
                            {{ (task.indexOf(item))+1 }}
                        </template>
                        <template v-slot:top>
                            <v-container >
                                <v-toolbar flat class="pa-3">
                                    <v-text-field filled dense outlined clearable v-model="searchResult" label="Search">
            
                                    </v-text-field>
                                </v-toolbar>
                            </v-container>
                        </template>
                    </v-data-table>
                </v-container>
            </v-col>
        </v-row>

    </v-container>
</template>

<script>
import { mapState } from 'vuex'
import AConfirmation from '../common/AConfirmation.vue';
import ANotAllowed from '../common/ANotAllowed.vue';
export default {
    components:{
    AConfirmation,
    ANotAllowed
},
    computed: mapState({
    //
    }),
    props:[
    //
    ],
    data: () => ({
        notAllowed:false,
        notAllowedAction:false,
        categoryOptions: ["All","Team","Personal"],
        tempTaskItem:null,
        tempStatusChecked:null,
        statusCheckedTrue:true,
        loadingUpdateStatus:false,
        statusCheckedFalse:false,
        tab:null,
        task:null,
        headersTask:[
            {
                text:'No.',
                value:'index',
            },
           
            {
                text:'Company',
                value:'company_name',
            },
            // {
            //     text:'Assigned to',
            //     value:'pic_name',
            // },  
            {
                text:'Action',
                value:'task_category',

            },
            {
                text:'Description',
                value:'task_action'
            },  
          
            {
                text:'Date',
                value:'created_at',
            },
            {
                text:'Id',
                value:'task_name',
            },

            // {
            //     text:'Deadline',
            //     value:'deadline_at',
            // },
            {
                text:'Priority',
                value:'priority'
            },
            {
                text:'Assigned by',
                value:'assignee_name',
            },
            // {
            //     text:'Category',
            //     value:'role_id',
            // },
            {
                text:'Completed by',
                value:'completed_by_name',  
            },
            {
                text:'Done',
                value:'task_status',

            },
           
        ],
        userRole:null,
        isPending:false,
        searchResult:null,
        searchCategory:'All',
        api:{
            isSuccesful:false,
            isError:false,
            isLoading:false,
            error:null,
            url:null,
        }
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class=="getTaskByStatus"){
                this.task = resp.data;
            }
            if(resp.class=="updateTaskStatus"){
                this.loadingUpdateStatus = false;
                location.reload();
            }
            this.api.isLoading = false;
            this.api.isError = false;
            this.isSucessful = true;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch(){
            this.userRole = this.$store.getters.getRole;
            let fetchTaskBasedOnUserCompleteApi = this.fetchTaskBasedOnUserComplete();
            this.$api.fetch(fetchTaskBasedOnUserCompleteApi);

        },
        fetchTaskBasedOnUserIncomplete(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/task/status/"+this.$store.getters.getUserId+"?status=To do";
            return tempApi;
        },
        fetchTaskBasedOnUserComplete(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/task/status/"+this.$store.getters.getUserId+"?status=Done&category="+this.searchCategory;
            return tempApi;
        },
        validateInput(taskItem,statusChecked){
            let allowedRoles = ["developer","operation"];
            this.loadingUpdateStatus = true;
            if(!allowedRoles.some(role=> this.$store.getters.getRole.includes(role))){

                this.statusCheckedTrue = true;
                this.notAllowed = true;
                return 1;
            }
            this.tempTaskItem = taskItem;
            this.tempStatusChecked = statusChecked;
            this.isPending = true;
        },
        submit()
        {
            this.isPending = false;
        },
        cancelSubmit(){
            this.statusCheckedTrue=true;
            this.loadingUpdateStatus = false;
            this.isPending = false;
        },
        redirectNewTask(){
            let companyId = this.$router.history.current.params.id;
            this.$router.push({name:'PageTaskCreate',params:{id:companyId}});
        },
        redirectReadTask(taskId){
            this.$router.push({name:'PageTaskDetail',params:{id:taskId}});
        },
        convertTimeZone(time){
            return this.$moment(time).format('LLL')
            // return new Date(new Date(time).toLocaleString("en-US",{timeZone:"Asia/Kuala_Lumpur"})).toString();
        },
        closeNotAllowed(){

            this.statusCheckedTrue = true;
            this.loadingUpdateStatus = false;
            this.notAllowed = false;
        },
        updateTaskStatus(){
            this.isPending = false;
            this.statusCheckedFalse = false;
            this.statusCheckedTrue = true;
            let task = this.$_.clone(this.tempTaskItem);
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "PUT";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/task/status/id/"+task.task_id;
            tempApi.params ={
                'status':'To do',
            };
           

            this.$axios.all([
                this.$api.fetch(tempApi),
            ]);
            // if(nextStatus==true){
            //     tempApi.params={
            //         'status':'Done',
            //     };
            // }

        },
        create(){

        },
    }
}
</script>