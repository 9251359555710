var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('AConfirmation',{attrs:{"isShow":_vm.isPending},on:{"cancel":_vm.cancelSubmit,"confirm":_vm.updateMultipleTaskStatus}}),(_vm.isTaskCompleted)?_c('ATaskComplete',{on:{"close":_vm.reloadPage}}):_vm._e(),(this.api.isLoading)?_c('v-skeleton-loader',{ref:"skeleton",attrs:{"type":"table"}}):_vm._e(),(!_vm.api.isLoading)?_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mx-auto mt-6"},[_c('v-container',[_c('v-data-table',{staticClass:"elevation-1 ",attrs:{"items":_vm.task,"search":_vm.searchResult,"headers":_vm.headersTask},scopedSlots:_vm._u([{key:"item.company_name",fn:function(ref){
var item = ref.item;
return [(item.company_id!=33)?_c('router-link',{attrs:{"to":{name:'PageCompaniesDetail',params:{id:item.company_id}}}},[_vm._v(" "+_vm._s(item.company_name)+" ")]):_c('span',[_vm._v(" - ")])]}},{key:"item.role_id",fn:function(ref){
var item = ref.item;
return [(item.pic_id!=null)?_c('span',[_vm._v(" "+_vm._s(item.pic_name)+" ")]):_vm._e(),(item.role_id!=null)?_c('span',[_vm._v(" "+_vm._s((item.role_name=='vision_admin')?"Admin":item.role_name.replaceAll('_',' '))+" ")]):_vm._e()]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.convertTimeZone(item.created_at))+" ")]}},{key:"item.priority",fn:function(ref){
var item = ref.item;
return [(item.priority=='Urgent')?_c('span',{staticClass:"font-weight-bold my-3 red--text"},[_vm._v(" "+_vm._s(item.priority)+" ")]):_vm._e(),(item.priority=='Medium')?_c('span',{staticClass:"font-weight-bold my-3 ",staticStyle:{"color":"#FBB955"}},[_vm._v(" Mid ")]):_vm._e(),(item.priority=='Low')?_c('span',{staticClass:"font-weight-bold my-3 green-text"},[_vm._v(" Low ")]):_vm._e()]}},{key:"item.task_category",fn:function(ref){
var item = ref.item;
return [(item.task_category=='Client Subscription Renewal'|| item.task_category=='Client Payment Failed')?_c('router-link',{attrs:{"to":{name:'PageCompaniesDetail',params:{'id':item.company_id}}}},[_vm._v(" "+_vm._s(item.task_category)+" ")]):_c('span',[_vm._v(" "+_vm._s(item.task_category)+" ")])]}},{key:"item.task_pickup",fn:function(ref){
var item = ref.item;
return [_c('div',{key:item.task_id},[(_vm.$store.getters.getCurrentTask!=null && _vm.$store.getters.getCurrentTask.includes(item.task_id))?_c('v-switch',{attrs:{"inset":"","label":"Active"},on:{"click":function($event){return _vm.setInactiveTask(item)}},model:{value:(_vm.isTaskActiveTrue),callback:function ($$v) {_vm.isTaskActiveTrue=$$v},expression:"isTaskActiveTrue"}}):_c('v-switch',{attrs:{"inset":"","label":"Inactive"},on:{"click":function($event){return _vm.setActiveTask(item)}},model:{value:(_vm.isTaskActiveFalse),callback:function ($$v) {_vm.isTaskActiveFalse=$$v},expression:"isTaskActiveFalse"}})],1)]}},{key:"item.index",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s((_vm.task.indexOf(item))+1)+" ")]}},{key:"item.task_status",fn:function(ref){
var item = ref.item;
return [_c('v-checkbox',{on:{"change":function($event){return _vm.updateCheckBox()}},model:{value:(item.checkbox),callback:function ($$v) {_vm.$set(item, "checkbox", $$v)},expression:"item.checkbox"}})]}},{key:"top",fn:function(){return [_c('v-toolbar',{staticClass:"pa-3",attrs:{"flat":"","height":"auto","width":"auto"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"mt-3 mx-3"},[_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"filled":"","dense":"","outlined":"","clearable":"","label":"Search"},model:{value:(_vm.searchResult),callback:function ($$v) {_vm.searchResult=$$v},expression:"searchResult"}})],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-0 pb-2"},[_c('div',{staticClass:"d-flex justify-end"},[_c('v-btn',{attrs:{"disabled":!_vm.isTaskSelected,"color":"primary"},on:{"click":_vm.validateInput}},[_vm._v(" Mark As Complete ")])],1)])],1)],1)],1)],1)]},proxy:true}],null,false,1207294706)})],1)],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }